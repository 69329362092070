import { Injectable } from "@angular/core";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class SwalPopupService {
  constructor() {}

  error() {
    Swal.fire({
      title: "Oops...",
      icon: "error",
      text: "Something went wrong",
      showCloseButton: false,
      showCancelButton: false,
      focusConfirm: true,
      confirmButtonText: "Ok",
      confirmButtonColor: "#00bbff",
      allowOutsideClick: false,
    });
  }

  customError(title, icon, text) {
    Swal.fire({
      title: title,
      icon: icon,
      text: text,
      showCloseButton: false,
      showCancelButton: false,
      focusConfirm: true,
      confirmButtonText: "Ok",
      confirmButtonColor: "#00bbff",
      allowOutsideClick: false,
    });
  }

  customMessageWithPromise(title, icon, text) {
    return Swal.fire({
      title: title,
      icon: icon,
      text: text,
      showCloseButton: false,
      showCancelButton: false,
      focusConfirm: true,
      confirmButtonText: "Ok",
      confirmButtonColor: "#00bbff",
      allowOutsideClick: false,
    });
  }

  customMessageWithHTML(title, icon, text, confirmBtnText) {
    return Swal.fire({
      title: title,
      icon: icon,
      html: text,
      showCloseButton: false,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: confirmBtnText,
      confirmButtonColor: "#00bbff",
      allowOutsideClick: false,
    });
  }

  delete() {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      focusCancel: true,
      allowOutsideClick: false,
    });
  }

  autoCloseMessage(title, icon, text){
    Swal.fire({
      title: title,
      icon: icon,
      text: text,
      timer: 1500,
      timerProgressBar: true,
      showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
    });
  }
}
