import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { CommonService } from "./shared/services/common.service";
import { SwalPopupService } from "./shared/services/swal-popup.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "SME-Directory";
  userName: any;
  isLoading = false;
  loginSub: Subscription;
  logoutSub: Subscription;

  constructor(
    private router: Router,
    private commonService: CommonService,
    private swal: SwalPopupService
  ) {}

  ngOnInit() {

    // sets the user data subscription for user login/logout status change
    if (!this.checkUser()) {
      this.getUserData();
    } else {
      this.commonService.setLogin(true);
      this.getUserData();
    }
    this.incrementAndGetVisitorCount();
    this.initiateLogoutOnSessionExpiration();
  }

  // Sets the username if user session exists
  checkUser() {
    if (sessionStorage.getItem("fullName")) {
      this.userName = sessionStorage.getItem("fullName");
      return true;
    } else {
      this.userName = null;
      return false;
    }
  }

  // Gets user login and session status, if true then sets the username
  getUserData() {
    this.loginSub = this.commonService.getLogin().subscribe((user) => {
      if (user) {
        this.userName = sessionStorage.getItem("fullName");
      } else this.userName = null;
    });
  }

  // In case of session expiration, calls the logout method
  initiateLogoutOnSessionExpiration() {
    this.logoutSub = this.commonService.getLogoutStatus().subscribe((value) => {
      if (value) {
        this.logout();
      }
    });
  }

  editProfile(){
    this.router.navigateByUrl(`/auth/edit-profile`);
  }

  // Clears the session data and logs out the user
  logout() {
    this.isLoading = true;
    sessionStorage.clear();
    this.commonService.setLogin(false);
    if (!this.loginSub) {
      this.checkUser();
    }
    setTimeout(() => {
      this.isLoading = false;
      this.router.navigateByUrl("");
    }, 500);
  }

  // Navigates to home page on click of the logo
  goToHomePage() {
    this.router.navigateByUrl("");
  }

  goToLogin() {
    this.router.navigateByUrl("/auth/login");
  }

  goToAddListing() {
    if (this.userName) this.router.navigateByUrl("/listing/add-listing");
    else this.router.navigateByUrl("/auth/login");
  }

  goToviewMyListings() {
    if (this.userName) this.router.navigateByUrl("/listing/view-my-listings");
    else this.router.navigateByUrl("/auth/login");
  }

  incrementAndGetVisitorCount() {
    this.commonService.incrementAndGetVisitorCount().subscribe(
      (res) => {
        if (res && res["responseCode"] === 200) {
          this.commonService.setvisitorCount(res["body"].visitorCount || 0);
        } else {
          // this.swal.error();
        }
      },
      (err) => {
        // this.swal.error();
      }
    );
  }
}
