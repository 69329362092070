import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
// import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from "rxjs";
import { CommonService } from "../shared/services/common.service";
import Swal from "sweetalert2";
import * as $ from "jquery";
import { SwalPopupService } from "../shared/services/swal-popup.service";
import { ViewListingService } from "./view-listing.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RatingComponent } from "./rating/rating.component";
import { Router } from "@angular/router";
import { ViewRatingComponent } from "./view-rating/view-rating.component";
// declare var $;

type State = { id: number; name: string };

const states: State[] = [
  { id: 0, name: "Alabama" },
  { id: 1, name: "Alaska" },
  { id: 2, name: "American Samoa" },
  { id: 3, name: "Arizona" },
  { id: 4, name: "Arkansas" },
  { id: 5, name: "California" },
  { id: 6, name: "Colorado" },
  { id: 7, name: "Connecticut" },
  { id: 8, name: "Delaware" },
  { id: 9, name: "District Of Columbia" },
  { id: 10, name: "Federated States Of Micronesia" },
  { id: 11, name: "Florida" },
  { id: 12, name: "Georgia" },
  { id: 13, name: "Guam" },
  { id: 14, name: "Hawaii" },
  { id: 15, name: "Idaho" },
  { id: 16, name: "Illinois" },
  { id: 17, name: "Indiana" },
  { id: 18, name: "Iowa" },
  { id: 19, name: "Kansas" },
  { id: 20, name: "Kentucky" },
  { id: 21, name: "Louisiana" },
  { id: 22, name: "Maine" },
  { id: 23, name: "Marshall Islands" },
  { id: 24, name: "Maryland" },
  { id: 25, name: "Massachusetts" },
  { id: 26, name: "Michigan" },
  { id: 27, name: "Minnesota" },
  { id: 28, name: "Mississippi" },
  { id: 29, name: "Missouri" },
  { id: 30, name: "Montana" },
  { id: 31, name: "Nebraska" },
  { id: 32, name: "Nevada" },
  { id: 33, name: "New Hampshire" },
  { id: 34, name: "New Jersey" },
  { id: 35, name: "New Mexico" },
  { id: 36, name: "New York" },
  { id: 37, name: "North Carolina" },
  { id: 38, name: "North Dakota" },
  { id: 39, name: "Northern Mariana Islands" },
  { id: 40, name: "Ohio" },
  { id: 41, name: "Oklahoma" },
  { id: 42, name: "Oregon" },
  { id: 43, name: "Palau" },
  { id: 44, name: "Pennsylvania" },
  { id: 45, name: "Puerto Rico" },
  { id: 46, name: "Rhode Island" },
  { id: 47, name: "South Carolina" },
  { id: 48, name: "South Dakota" },
  { id: 49, name: "Tennessee" },
  { id: 50, name: "Texas" },
  { id: 51, name: "Utah" },
  { id: 52, name: "Vermont" },
  { id: 53, name: "Virgin Islands" },
  { id: 54, name: "Virginia" },
  { id: 55, name: "Washington" },
  { id: 56, name: "West Virginia" },
  { id: 57, name: "Wisconsin" },
  { id: 58, name: "Wyoming" },
];

@Component({
  selector: "app-view-listing",
  templateUrl: "./view-listing.component.html",
  styleUrls: ["./view-listing.component.scss"],
})
export class ViewListingComponent implements OnInit {
  categories = [];
  // categories = [
  //   {
  //     id: 1,
  //     name: 'Food & Beverages',
  //     icon: 'icon-food',
  //     active: false,
  //   },
  //   {
  //     id: 2,
  //     name: 'Hospitality',
  //     icon: 'icon-hospitality',
  //     active: false,
  //   },
  //   {
  //     id: 3,
  //     name: 'Cleaning',
  //     icon: 'icon-cleaning',
  //     active: false,
  //   },
  //   {
  //     id: 4,
  //     name: 'Air Conditioning',
  //     icon: 'icon-ac',
  //     active: false,
  //   },
  //   {
  //     id: 5,
  //     name: 'Electrical',
  //     icon: 'icon-electrical',
  //     active: false,
  //   },
  //   {
  //     id: 6,
  //     name: 'Plumbing',
  //     icon: 'icon-plumbing',
  //     active: false,
  //   },
  //   {
  //     id: 7,
  //     name: 'Handyman',
  //     icon: 'icon-handyman',
  //     active: false,
  //   },
  //   {
  //     id: 8,
  //     name: 'Tele-communication',
  //     icon: 'icon-telecomm',
  //     active: false,
  //   },
  //   {
  //     id: 9,
  //     name: 'Construction',
  //     icon: 'icon-construction',
  //     active: false,
  //   },
  //   {
  //     id: 10,
  //     name: 'Automotive',
  //     icon: 'icon-automotive',
  //     active: false,
  //   },
  //   {
  //     id: 11,
  //     name: 'Healthcare',
  //     icon: 'icon-healthcare',
  //     active: false,
  //   },
  //   {
  //     id: 12,
  //     name: 'Shopping',
  //     icon: 'icon-shopping',
  //     active: false,
  //   },
  //   {
  //     id: 13,
  //     name: 'Others',
  //     icon: 'icon-others',
  //     active: false,
  //   },
  // ];
  businessData = [];
  listings = [];
  pageSize = 9;
  p: number = 1;
  totalItems: number;
  searchResults = false;
  isGridView = true;
  isLoading = false;
  public model: State;
  searchTextWhat: string;
  searchTextWhere: string;
  searchType: string;
  selectedCategory: string;
  form: NgForm;
  searchForm = {
    what: "",
    where: "",
  };
  districts = [];
  selectedDistrict: any;
  visitorCount = 0;
  listingCount = 0;

  constructor(
    private commonService: CommonService,
    private viewListingService: ViewListingService,
    private swal: SwalPopupService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit() {
    this.commonService
      .getvisitorCount()
      .subscribe((count) => (this.visitorCount = count));
    this.initializeCategories();
    this.initializeWhereDDL();
  }

  initializeCategories() {
    this.viewListingService.getCategoriesAndBusinessCount().subscribe(
      (res) => {
        if (res && res["responseCode"] === 200) {
          this.categories = res["body"];

          this.categories.forEach((el) => {
            this.listingCount += el.businessCount;
          });
        } else {
          // this.swal.error();
        }
      },
      (err) => {
        // this.swal.error();
      }
    );
  }

  // Load the districts data into the DDL
  initializeWhereDDL() {
    this.commonService.getDistricts().subscribe(
      (res) => {
        if (res && res["responseCode"] === 200) {
          this.districts = res["body"];
        } else {
          // this.swal.error();
        }
      },
      (err) => {
        // this.swal.error();
      }
    );
  }

  // Method to search by the text entered by the user
  searchBusinessByText(f: NgForm, pageNo = 0, pagination = false) {
    this.searchTextWhat = "";
    this.searchTextWhere = "";
    this.searchType = "text";
    this.form = f;
    this.totalItems = null;

    if (f.value.what && f.value.where) {
      this.selectedDistrict = this.districts.find(
        (el) => el.id === parseInt(f.value.where)
      );
      this.searchTextWhat = f.value.what;
      this.searchTextWhere = this.selectedDistrict.name;
    } else if (f.value.what) {
      this.searchTextWhat = f.value.what;
    } else if (f.value.where) {
      this.selectedDistrict = this.districts.find(
        (el) => el.id === parseInt(f.value.where)
      );
      this.searchTextWhere = this.selectedDistrict.name;
    }

    this.searchResults = true;
    this.isLoading = true;

    if (!pagination) {
      this.p = 1;
      setTimeout(() => {
        this.scrollTo("divBusinessListings");
      }, 0);
    }

    let body = {
      what: f.value.what || null,
      where: parseInt(f.value.where),
      pageSize: this.pageSize,
      pageNo: pageNo,
      sortBy: "company_name",
    };
    this.activateDeactivateCategoryTile(null);

    this.viewListingService.searchBusinessByText(body).subscribe(
      (res) => {
        if (res && res["responseCode"] === 200 && res["body"].searchResult) {
          this.businessData = res["body"].searchResult;
          this.totalItems = res["body"].totalCount;
        } else {
          this.businessData = [];
          this.swal.error();
        }
        this.isLoading = false;
      },
      (err) => {
        this.businessData = [];
        this.isLoading = false;
        this.swal.error();
      }
    );
  }

  // Method to search by the category selected by the user
  searchBusinessByCategory(category, pageNo = 0, pagination = false) {
    this.searchTextWhat = "";
    this.searchTextWhere = "";
    this.searchType = "category";
    this.searchTextWhat = category.categoryName;
    this.selectedCategory = category;
    this.totalItems = null;

    this.searchResults = true;
    this.isLoading = true;

    if (!pagination) {
      this.p = 1;
      setTimeout(() => {
        this.scrollTo("divBusinessListings");
      }, 0);
    }

    let body = {
      categoryId: category.catgeoryId,
      where: 1,
      pageSize: this.pageSize,
      pageNo: pageNo,
      sortBy: "company_name",
    };

    this.viewListingService.searchBusinessByCategory(body).subscribe(
      (res) => {
        if (res && res["responseCode"] === 200) {
          this.businessData = res["body"].searchResult;
          this.totalItems = res["body"].totalCount;
        } else {
          this.businessData = [];
          this.swal.error();
        }
        this.isLoading = false;
      },
      (err) => {
        this.businessData = [];
        this.isLoading = false;
        this.swal.error();
      }
    );

    this.resetSearchForm();
    this.activateDeactivateCategoryTile(category);
  }

  // logic for activating category CSS
  activateDeactivateCategoryTile(category) {
    if (category) {
      this.categories.forEach((item) => {
        if (item.categoryName !== category.categoryName && item.isActive) {
          item.isActive = false;
        } else if (item.categoryName === category.categoryName) {
          item.isActive = true;
        }
      });
    } else {
      this.categories.forEach((item) => (item.isActive = false));
    }
  }

  // Method to reset the search form on click of category tile
  resetSearchForm() {
    this.searchForm.what = "";
    this.searchForm.where = "";
  }

  // Method to switch between Grid and List views
  toggleViewType() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
      this.isGridView = !this.isGridView;
    }, 200);
  }

  // Method to scroll to the business listings on search
  scrollTo(id) {
    let el = $(`#${id}`);
    el[0].scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }

  getWebUrl(link) {
    if(link.startsWith("https://") || link.startsWith("http://")){
      return link;
    } else {
      return "https://" + link;
    }
  }

  // Method to search business listings based on selected "Sort By" value
  sortBy(value) {
    console.log(value);
  }

  // Open the Rating Pop-Up Modal
  openRatingModal(type, business) {
    if (sessionStorage.getItem("accessToken")) {
      const data = {
        type,
        business,
      };

      const modalRef = this.modalService.open(RatingComponent);
      modalRef.componentInstance.data = data;

      // Callback Function for when modal is closed
      modalRef.result.then(
        (result) => {
          if (result === 'success') {
            let pagination = this.p > 1 ? true : false;
            if (this.searchType === "text") {
              this.searchBusinessByText(this.form, this.p - 1, pagination);
            } else if (this.searchType === "category") {
              this.searchBusinessByCategory(this.selectedCategory, this.p - 1, pagination);
            }
          }
        },
        (reason) => {}
      );
    } else {
      this.swal.customMessageWithPromise('Warning', 'warning', 'You need to be logged in to rate a business.')
      .then(result=>{
        this.router.navigateByUrl("/auth/login");
      })
    }
  }

  // Open the View Rating Pop-Up Modal
  openViewRatingModal(business) {

      const modalRef = this.modalService.open(ViewRatingComponent);
      modalRef.componentInstance.data = business;

      // Callback Function for when modal is closed
      modalRef.result.then(
        (result) => {
        },
        (reason) => {}
      );
  }

  // Method to search business listings based on selected "Page Number"
  pageChanged(value) {
    this.p = value;

    if (this.searchType === "text") {
      this.searchBusinessByText(this.form, value - 1, true);
    } else if (this.searchType === "category") {
      this.searchBusinessByCategory(this.selectedCategory, value - 1, true);
    }
  }
}
