import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ViewListingComponent } from './view-listing/view-listing.component';

const routes: Routes = [
  { path: '', component: ViewListingComponent },
  {
    path: 'listing',
    loadChildren: () =>
      import('./listing/listing.module').then((m) => m.ListingModule),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth/auth.module').then((m) => m.AuthModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
