import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { SwalPopupService } from "../services/swal-popup.service";
import { CommonService } from "../services/common.service";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private swal: SwalPopupService,
    private commonService: CommonService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token = sessionStorage.getItem("accessToken");
    let modifiedRequest;
    if (token) {
      modifiedRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      modifiedRequest = request.clone();
    }
    return next.handle(modifiedRequest).pipe(
      map((event) => {
        if (event instanceof HttpResponse) {
          if (event instanceof HttpResponse) {
            // console.log("Interceptor Response ==>", event);
            if (
              event['body'].statusCode === 400 &&
              event['body'].error === 'Access Token has expired'
            ) {
              // sessionStorage.clear();
              this.swal
                .customMessageWithPromise(
                  'Oops...',
                  'error',
                  'Session expired. Please login again.'
                )
                .then((result) => {
                  this.commonService.setLogoutStatus(true);
                });
              // return new Observable<HttpEvent<any>>();
              return;
            } else return event;
          } else return event;
        }
        return event;
      }),
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          // console.log("Interceptor Error==>", error);
          if (error.status === 401 && error.error.error === "Unauthorized") {
            // sessionStorage.clear();
            this.swal
              .customMessageWithPromise(
                "Oops...",
                "error",
                "Session expired. Please login again."
              )
              .then((result) => {
                this.commonService.setLogoutStatus(true);
              });
              return new Observable<HttpEvent<any>>(); 
          } else {
            // this.swal.customError(
            //   "Oops...",
            //   "error",
            //   "Internal Server Error."
            // ); 
            // return new Observable<HttpEvent<any>>();
          }
        }
        return new Observable<HttpEvent<any>>(error);
      })
    );
    // }
  }
}
