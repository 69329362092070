import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SwalPopupService } from "src/app/shared/services/swal-popup.service";
import { ViewListingService } from "../view-listing.service";

@Component({
  selector: "app-rating",
  templateUrl: "./rating.component.html",
  styleUrls: ["./rating.component.scss"],
})
export class RatingComponent implements OnInit {
  @Input() data;
  userName = '';
  rating: number;
  reviewTitle = "";
  reviewComment = "";
  hoveredRating = "";
  selectedRating = "";
  reviewError = false;
  isLoading = false;

  constructor(
    public activeModal: NgbActiveModal,
    private lisingService: ViewListingService,
    private swal: SwalPopupService
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.userName = sessionStorage.getItem('fullName');
    this.getUserRating();
  }

  getUserRating(){
    this.isLoading = true;

    this.lisingService.getUserRating(this.data.business.listingId).subscribe(
      (res) => {
        if (res && res['responseCode'] === 200){
          this.isLoading = false;
          this.rating = res['body'].rating;
          this.reviewTitle = res['body'].reviewTitle || '';
          this.reviewComment = res['body'].reviewComment || '';
          this.selectRating();
        } else if (res && res['responseCode'] !== 200) {
          this.isLoading = false;
        } else {
          this.isLoading = false;
          this.swal.error();
        }
      },
      (err) => {
        this.isLoading = false;
        this.swal.error();
      }
    )
  }

  hoverRating(event) {
    // if (!this.rating) {
      switch (event) {
        case 1: {
          this.hoveredRating = "Very Poor";
          break;
        }
        case 2: {
          this.hoveredRating = "Poor";
          break;
        }
        case 3: {
          this.hoveredRating = "Fair";
          break;
        }
        case 4: {
          this.hoveredRating = "Good";
          break;
        }
        case 5: {
          this.hoveredRating = "Very Good";
          break;
        }
      }
    // }
  }

  selectRating() {
    if (this.rating) {
      switch (this.rating) {
        case 1: {
          this.selectedRating = "Very Poor";
          break;
        }
        case 2: {
          this.selectedRating = "Poor";
          break;
        }
        case 3: {
          this.selectedRating = "Fair";
          break;
        }
        case 4: {
          this.selectedRating = "Good";
          break;
        }
        case 5: {
          this.selectedRating = "Very Good";
          break;
        }
      }
    }
  }

  setReviewError() {
    if (!this.reviewTitle && !this.reviewComment) {
      this.reviewError = false;
    } else if (this.reviewTitle && this.reviewComment) {
      this.reviewError = false;
    } else {
      this.reviewError = true;
    }
  }

  rateNow() {
    this.isLoading = true;
    let body = {
      listingId: this.data.business.listingId,
      rating: this.rating,
      reviewTitle: this.reviewTitle || null,
      reviewComment: this.reviewComment || null,
    };

    this.lisingService.rateNow(body).subscribe(
      (res) => {
        if (res && res['responseCode'] === 200){
          this.swal.customMessageWithPromise('Success', 'success', 'Rating saved successfully.')
          .then(result=>{
            this.isLoading = false;
            this.activeModal.close('success');
          })
        } else if (res && res['responseCode'] !== 200) {
          this.isLoading = false;
          this.swal.customError('Error', 'error', res['responseStatus'])
        } else {
          this.isLoading = false;
          this.swal.error();
        }
      },
      (err) => {
        this.isLoading = false;
        this.swal.error();
      }
    );
  }
}
