import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonService } from "src/app/shared/services/common.service";
import { SwalPopupService } from "src/app/shared/services/swal-popup.service";
import { ViewListingService } from "../view-listing.service";

@Component({
  selector: "app-view-rating",
  templateUrl: "./view-rating.component.html",
  styleUrls: ["./view-rating.component.scss"],
})
export class ViewRatingComponent implements OnInit {
  @Input() data;
  rating: number;
  ratingDetails: any;
  isLoading = false;
  reviewPageSize = 9;
  pageNo: number = 1;
  totalreviews: number;
  reviewFilter = 0;
  reviewDetails = [];

  constructor(
    public activeModal: NgbActiveModal,
    private lisingService: ViewListingService,
    private swal: SwalPopupService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.getBusinessRatingInfo();
    this.getBusinessReviews();
  }

  getBusinessRatingInfo() {
    this.isLoading = true;

    this.lisingService.getBusinessRatingInfo(this.data.listingId).subscribe(
      (res) => {
        if (res && res["responseCode"] === 200) {
          this.isLoading = false;
          this.ratingDetails = res["body"];
        } else {
          this.isLoading = false;
          this.swal.customError("Error", "error", "Server Error");
        }
      },
      (err) => {
        this.isLoading = false;
        this.swal.error();
      }
    );
  }

  getBusinessReviews(pageNo = 0) {
    this.isLoading = true;

    let body = {
      listingId: this.data.listingId,
      pageNo: pageNo,
      pageSize: this.reviewPageSize,
      filter: this.reviewFilter,
    };

    this.lisingService.getBusinessReviews(body).subscribe(
      (res) => {
        if (res && res["responseCode"] === 200) {
          this.isLoading = false;
          this.reviewDetails = res["body"].reviews;
          this.totalreviews = res["body"].totalCount;
        } else {
          this.isLoading = false;
          this.swal.customError("Error", "error", "Server Error");
        }
      },
      (err) => {
        this.isLoading = false;
        this.swal.error();
      }
    );
  }

  getDate(date){
    if (this.commonService.getBrowserName() === "Safari") {
      date = new Date(date.replace(/\s/g, "T"));
    }
    return new Date(date);
  }

  filterReviews(value){
    this.reviewFilter = value;
    this.getBusinessReviews();
  }

  pageChanged(value) {
    this.pageNo = value;
    this.getBusinessReviews(value - 1);
  }
}
