<div class="modal-header">
  <div class="col-11 rate">
    <div class="business-name text-primary">
      {{ data.businessName }}
      <!-- <span class="ssm"> {{data.business.ssmNo ? '(' +data.business.ssmNo+ ')' : ''}}</span> -->
    </div>
    <div class="col-12 address px-0">{{ data.address }}</div>
  </div>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body pr-3">
  <div class="row ratingDetails">
    <!-- <div class="col-12 rate mb-4 ml-2" *ngIf="data.type == 'details'">
        <div class="business-name text-primary">{{data.business.businessName}}
          <span class="ssm"> {{data.business.ssmNo ? '(' +data.business.ssmNo+ ')' : ''}}</span>
        </div>
        <div class="col-12 address px-0">{{ data.business.address }}</div>
      </div> -->
    <!-- ============================================================== -->
    <!-- Preloader - style you can find in spinners.css -->
    <!-- ============================================================== -->
    <div class="preloader" *ngIf="isLoading">
      <div class="loader">
        <div class="loader__figure"></div>
        <p class="loader__label">Smart Selangor</p>
      </div>
    </div>

    <div class="col-4 col-sm-3">
      <div class="row rating-point ml-0 mb-3">
        <div class="col-12 px-0">
          {{ ratingDetails?.averageRating || "0" }}
        </div>
      </div>
      <div class="row ml-3 mb-1">
        <ng-template #t let-fill="fill">
          <span class="star" [class.full]="fill === 100">
            <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
          </span>
        </ng-template>

        <ngb-rating
          [rate]="ratingDetails?.averageRating"
          [starTemplate]="t"
          [readonly]="true"
          [max]="5"
        ></ngb-rating>
      </div>
      <div class="row ml-2 text-center">
          <div class="col-12 px-0">
            <em class="fa fa-user mr-1"></em>
            <span class="rating-count">
              {{ ratingDetails?.totalUserCount || "0" | number }}
            </span>
          </div>
      </div>
    </div>
    <div class="col-8 col-sm-9">
      <div class="row">
        <div class="col-2 col-sm-2 mb-2 pr-0">5 &#9733;</div>
        <ngb-progressbar
          class="col-8 col-sm-9 pl-0"
          type="success"
          [value]="ratingDetails?.fiveStarPercentage"
        ></ngb-progressbar>
        <div class="col-1 px-0">{{ratingDetails?.fiveStarPercentage || '0'}}%</div>
      </div>
      <div class="row mt-1">
        <div class="col-2 col-sm-2 mb-2 pr-0">4 &#9733;</div>
        <ngb-progressbar
          class="col-8 col-sm-9 pl-0"
          type="green"
          [value]="ratingDetails?.fourStarPercentage"
        ></ngb-progressbar>
        <div class="col-1 px-0">{{ratingDetails?.fourStarPercentage || '0'}}%</div>
      </div>
      <div class="row mt-1">
        <div class="col-2 col-sm-2 mb-2 pr-0">3 &#9733;</div>
        <ngb-progressbar
          class="col-8 col-sm-9 pl-0"
          type="yellow"
          [value]="ratingDetails?.threeStarPercentage"
        ></ngb-progressbar>
        <div class="col-1 px-0">{{ratingDetails?.threeStarPercentage || '0'}}%</div>
      </div>
      <div class="row mt-1">
        <div class="col-2 col-sm-2 mb-2 pr-0">2 &#9733;</div>
        <ngb-progressbar
          class="col-8 col-sm-9 pl-0"
          type="orange"
          [value]="ratingDetails?.twoStarPercentage"
        ></ngb-progressbar>
        <div class="col-1 px-0">{{ratingDetails?.twoStarPercentage || '0'}}%</div>
      </div>
      <div class="row mt-1">
        <div class="col-2 col-sm-2 mb-2 pr-0">1 &#9733;</div>
        <ngb-progressbar
          class="col-8 col-sm-9 pl-0"
          type="danger"
          [value]="ratingDetails?.oneStarPercentage"
        ></ngb-progressbar>
        <div class="col-1 px-0">{{ratingDetails?.oneStarPercentage || '0'}}%</div>
      </div>
    </div>
  </div>
  <hr style="margin: 1rem -1rem;">
  <div class="row px-4 mb-2">
    <div class="col-8 pt-1 reviewsLabel">
      Reviews
    </div>
    <div class="col-4">
      <select name="filter" id="filter" class="form-control btn btn-primary d-inline-block" (change)="filterReviews($event.target.value)">
        <option selected disabled value="">Filter</option>
        <option value="0">All</option>
        <option value="1">1 star</option>
        <option value="2">2 star</option>
        <option value="3">3 star</option>
        <option value="4">4 star</option>
        <option value="5">5 star</option>
      </select>
    </div>
  </div>
  
  <div class="row reviewDetails px-4 mb-2" *ngFor="let item of reviewDetails | paginate: { id: 'reviewPagination', itemsPerPage: reviewPageSize, currentPage: pageNo, totalItems: totalreviews }">
    <div class="col-12 userName">
      <span class="far fa-user-circle"></span>
      {{item.userName}}
    </div>
    <div class="reviewRating d-inline-block pr-2">
      <ng-template #r let-fill="fill">
        <span class="star" [class.full]="fill === 100">
          <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
        </span>
      </ng-template>

      <ngb-rating
        [rate]="item.rating"
        [starTemplate]="r"
        [readonly]="true"
        [max]="5"
      ></ngb-rating>
    </div>
    <div class="d-inline-block reviewDate pt-1">{{getDate(item.date) | date: 'longDate'}}</div>
    <div class="col-12 reviewTitle">{{item.reviewTitle}}</div>
    <div class="col-12 reviewComment">{{item.reviewComment}}</div>
  </div>
  <div class="row px-4" *ngIf="!reviewDetails.length">
    <div class="col-12">
      No Data Found
    </div>
  </div>
  <!-- Custom Pagination Control -->
  <div class="col-12 text-center mt-4">
    <pagination-controls id="reviewPagination" class="my-pagination" (pageChange)="pageChanged($event)" maxSize="5"></pagination-controls>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close('cancel click')"
  >
    Close
  </button>
</div>
