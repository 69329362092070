<div class="container">
  <div class="row row1">
    <div class="col-md-12 big-titles">
      <span class="title1"> Smart Selangor </span>
      <span class="title2"> SME Directory </span>
    </div>
    <div class="col-12 title1 small-titles">Smart Selangor</div>
    <div class="col-12 title2 small-titles">SME Directory</div>
    <div class="col-12 subtitle mt-3">
      Find a product, service, or company you're looking for.
    </div>

    <form class="col-12 form my-4" novalidate #f='ngForm' (ngSubmit)='searchBusinessByText(f)'>
      <div class="row mx-0">
        <div class="col-12 col-sm-12 col-md-6 what mb-2 mr-1">
          <label for="typeahead-prevent-manual-entry">What</label>
          <input
            id="what"
            type="text"
            class="form-control"
            name="what"
            #what
            placeholder="Service Type / Company Name"
            [(ngModel)]="searchForm.what"
          />
        </div>
        <div class="col-8 col-sm-8 col-md-4 where mb-2">
          <label for="typeahead-prevent-manual-entry">Where</label>
          <select class="form-control" #where [(ngModel)]='searchForm.where'
            name='where'>
            <option selected value=''>Select District</option>
            <option *ngFor='let dist of districts' value='{{dist.id}}'>
              {{dist.name}}</option>
          </select>
          <span class="fa fa-chevron-down"></span>
        </div>
        <div class="col-4 col-sm-4 col-md-2 search-btn pr-0">
          <button type="submit" class="btn btn-primary" [disabled] = '!what.value && !where.value'>
            <em class="fa fa-search"></em>
            Search
          </button>
        </div>

      </div>
    </form>

    <div class="col-12 menu">
      <div class="row mx-0">
        <div class="col-12 px-0 my-2">Or browse the highlights</div>
        <div class="col-12 mt-4 text-center"  *ngIf="!categories.length">
          <div class="spinner-border text-primary"></div>
        </div>
        <div class="category-container" [class.active-category]="item.isActive" *ngFor="let item of categories" (click)="searchBusinessByCategory(item)">
          <span class="badge" [class.badge-primary]="!item.isActive" [class.badge-invert]="item.isActive">
            {{item.businessCount}}
          </span>
          <div class="icons mt-2">
            <span [class]="'icon ' + item.icon"></span>
          </div>
          <div class="category-name">
            {{ item.categoryName }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Application Guide Text Section Starts -->
  <div class="row row2" *ngIf="!searchResults">

    <!-- <div class="col-12 col-sm-7 col-md-7 visitor-text mb-2">Total Visitors: <span class="visitor-count">{{visitorCount || 0}}</span></div>
    <div class="col-12 col-sm-5 col-md-5 visitor-text mb-4">Total Listings: <span class="visitor-count">{{listingCount || 0}}</span> </div> -->

    <div class="col-12 page-visitors">
      <div class="visitor-text d-inline-block mb-2 mr-4">Page Visitors: 
        <div class="spinner-border text-primary ml-2" *ngIf="!visitorCount"></div>
        <span class="visitor-count" *ngIf="visitorCount">{{visitorCount || 0}}</span>
      </div>
      <div class="visitor-text d-inline-block mb-4">Total Listings: 
        <div class="spinner-border text-primary ml-2" *ngIf="!listingCount"></div>
        <span class="visitor-count" *ngIf="listingCount">{{listingCount || 0}}</span> 
      </div>
    </div>

    <div class="col-12 guide"><strong>See</strong> How It Works</div>
    <div class="col-12">
      <div class="row">
        <div class="col-12 col-lg-4">
          <div class="row">
            <div class="col-3 col-md-2 col-lg-3 col-xl-2 num">01</div>
            <div class="col-9 col-md-10 col-lg-9 col-xl-10">
              <div class="col-12 title">Choose What To Do</div>
              <div class="col-12 text">Looking for a plumber, a cleaning service provider or a restaurant to eat?</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="row">
            <div class="col-3 col-md-2 col-lg-3 col-xl-2 num">02</div>
            <div class="col-9 col-md-10 col-lg-9 col-xl-10">
              <div class="col-12 title">Find What You Want</div>
              <div class="col-12 text">Search and filter hundreds of listings and find the perfect service provider.</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="row">
            <div class="col-3 col-md-2 col-lg-3 col-xl-2 num">03</div>
            <div class="col-9 col-md-10 col-lg-9 col-xl-10">
              <div class="col-12 title">Engage a Service Provider</div>
              <div class="col-12 text">Engage with the service provider directly from the listing page.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Application Guide Text Section Ends -->

  <!-- Listings Section Starts -->
  <div class="row row2" *ngIf="searchResults" id="divBusinessListings">

    <div class="col-12 breadcrum">Home / <span class="text-primary" style="font-weight: 500;"> Listings </span></div>
    <div class="col-12 px-3" *ngIf="businessData.length">
      <div class="row mt-3 mb-2">
        <div class="col-lg-3 view-type">
          <span class="fa fa-th" [class.text-primary] = "isGridView" (click) = "toggleViewType()"></span>
          <span class="fa fa-th-list ml-2" [class.text-primary] = "!isGridView" (click) = "toggleViewType()"></span>
        </div>
        <div class="col-lg-4 col-md-7 col-8">
          <span class="no-results">{{totalItems}}</span>
          <span class="results-text"> results </span>
          <span class="results-text" *ngIf="searchTextWhat"> for </span>
           <span class="no-results">{{searchTextWhat}}</span>
           <span class="results-text" *ngIf="searchTextWhere"> in </span>
           <span class="no-results">{{searchTextWhere}}</span>
          </div>
        <div class="col-lg-4 offset-lg-1 col-md-5 col-4 text-right">
          <!-- <label for="">Sort by &nbsp;</label> -->
          <select class="form-control btn btn-primary" style=" display: inline-block;" (change)="sortBy($event.target.value)">
            <option disabled selected value=''>Sort By</option>
            <option value="company_name">Company Name</option>
            <!-- <option value="current_rating">Rating</option> -->
          </select>
        </div>
      </div>
    </div>

    <div *ngIf="isLoading" class="col-3 offset-5 mt-4 lds-ring"><div></div><div></div><div></div><div></div></div>

    <div class="col-12 mt-4 text-center" *ngIf="!businessData.length && !isLoading">No data found.</div>

    <!-- Grid View Starts -->
    <div class="col-12 px-0" *ngIf="businessData.length">
      <div class="row mx-0 gridView" *ngIf="isGridView && !isLoading">
        <!-- Listing Cards -->
        <div class="col-12 col-md-6 col-lg-4 grid-container"
          *ngFor="let item of businessData | paginate: { itemsPerPage: pageSize, currentPage: p, totalItems: totalItems }"
        >
        <!-- Business Cards Start-->
          <div class="card">
            <div class="rating text-right pr-3 my-1">
              <span class="rateNow text-primary mr-1" (click)="openRatingModal('rate', item)">Rate Now</span>
              <ng-template #a let-fill="fill">
                <span class="star" [class.full]="fill === 100">
                  <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                </span>
              </ng-template>

              <ngb-rating [(rate)]="item.rating" [starTemplate]="a" (click)="openViewRatingModal(item)" [readonly]="true" [max]="5"></ngb-rating>
              <!-- <ngb-rating [rate]="item.rating" [max]="5" [readonly]="true" class="rating"></ngb-rating> -->
              <span class="no-rating"> ({{item.ratingCount || 0 }})</span>
            </div>
            <div class="card-img" [style.background-image]="'url(' + item.logoUrl + ')'"></div>
            <div class="card-body">
              <div class="card-title">{{item.businessName}}<span class="ssm"> {{item.ssmNo ? '(' +item.ssmNo+ ')' : ''}}</span></div>
              <hr class="my-2">
              <div class="category"><span style="color: #818181;">in</span> {{item.serviceName}}</div>
              <p class="address">
                {{item.address}}
              </p>
              <div class="text-center" *ngIf="item.ctaUrl">
                <!-- <button class="btn btn-primary btn-cta" (click)="openWebsite(item.ctaUrl)">{{item.ctaText}}</button> -->
                <a [href]="getWebUrl(item.ctaUrl)" target="_blank" class="btn btn-primary btn-cta">{{item.ctaText}}</a>
              </div>
            </div>

            <hr class="my-0 mx-4">

            <div class="card-icons text-center">
              <a [href]="'//www.google.com/maps/search/?api=1&query='+item.latitude+','+item.longitude" target="_blank" *ngIf="item.latitude && item.longitude">
                <em class="fa fa-map-marker-alt"></em>
              </a>
              <em class="fa fa-map-marker-alt social-no-data" *ngIf="!item.latitude || !item.longitude"></em>
              <em class="brd-right"></em>

              <a [href]="'tel:' + item.phoneNo" [ngbTooltip]="item.phoneNo" *ngIf="item.phoneNo">
                <em class="fa fa-phone-volume"></em>
              </a>
              <em class="fa fa-phone-volume social-no-data" *ngIf="!item.phoneNo"></em>
              <em class="brd-right"></em>

              <a [href]="'mailto: ' + item.email" target="_blank" [ngbTooltip]="item.email" *ngIf="item.email">
                <em class="fa fa-envelope"></em>
              </a>
              <em class="fa fa-envelope social-no-data" *ngIf="!item.email"></em>
              <em class="brd-right"></em>

              <a [href]="getWebUrl(item.website)" target="_blank" [ngbTooltip]="item.website" *ngIf="item.website">
                <em class="fa fa-globe"></em>
              </a>
              <em class="fa fa-globe social-no-data" *ngIf="!item.website"></em>
              <em class="brd-right"></em>

              <em class="fa fa-info-circle" [class.social-no-data]="!item.businessDescription" [ngbTooltip]="item.businessDescription" #t='ngbTooltip' placement="left" (click)="t.open()"></em>
            </div>

            <hr class="my-0 mx-4">

            <div class="card-icons text-center">
              <a [href]="getWebUrl(item.wpUrl)" target="_blank" [ngbTooltip]="item.wpUrl" *ngIf="item.wpUrl">
                <em class="fab fa-whatsapp"></em>
              </a>
              <em class="fab fa-whatsapp social-no-data" *ngIf="!item.wpUrl"></em>
              <em class="brd-right"></em>

              <a [href]="getWebUrl(item.fbUrl)" target="_blank" [ngbTooltip]="item.fbUrl" *ngIf="item.fbUrl">
                <em class="fab fa-facebook-f"></em>
              </a>
              <em class="fab fa-facebook-f social-no-data" *ngIf="!item.fbUrl"></em>
              <em class="brd-right"></em>

              <a [href]="getWebUrl(item.instaUrl)" target="_blank" [ngbTooltip]="item.instaUrl" *ngIf="item.instaUrl">
                <img class="fab instagram" src="../../assets/instagram-logo-png-2426.png" alt="insta" />
              </a>
              <em class="fab fa-instagram social-no-data" *ngIf="!item.instaUrl"></em>
              <em class="brd-right"></em>

              <a [href]="getWebUrl(item.twitterUrl)" target="_blank" [ngbTooltip]="item.twitterUrl" *ngIf="item.twitterUrl">
                <em class="fab fa-twitter"></em>
              </a>
              <em class="fab fa-twitter social-no-data" *ngIf="!item.twitterUrl"></em>
              <em class="brd-right"></em>

              <a [href]="getWebUrl(item.linkedinUrl)" target="_blank" *ngIf="item.linkedinUrl">
                <em class="fab fa-linkedin-in"></em>
              </a>
              <em class="fab fa-linkedin-in social-no-data" *ngIf="!item.linkedinUrl"></em>

            </div>
          </div>
        <!-- Business Cards End -->
        </div>

        <!-- Custom Pagination Control -->
        <div class="col-12 text-center mt-4">
          <pagination-controls class="my-pagination" (pageChange)="pageChanged($event)" maxSize="5"></pagination-controls>
        </div>
      </div>
    </div>
    <!-- Grid View Ends -->

    <!-- List View Starts -->
    <div class="col-12 px-0" *ngIf="businessData.length">
      <div class="row mx-0 listView" *ngIf="!isGridView && !isLoading">
        <!-- Listing Cards -->
        <div class="col-12"
          *ngFor="let item of businessData | paginate: { itemsPerPage: pageSize, currentPage: p, totalItems: totalItems }"
        >
        <!-- Business List Cards Starts-->
        <!-- Class "grid-container" for common CSS -->
        <div class="row grid-container list-container py-3">
          <div class="col-2 px-0">
            <div class="card-img list-card-img" [style.background-image]="'url(' + item.logoUrl + ')'"></div>
          </div>
          <div class="col-10">
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-9 card-title">
                    {{item.businessName}}<span class="ssm"> {{item.ssmNo ? '(' +item.ssmNo+ ')' : ''}}</span>
                    <div class="category mb-3"><span style="color: #818181;">in</span> {{item.serviceName}}</div>
                  </div>
                  <div class="col-3 text-right">
                    <ngb-rating [rate]="item.rating" [max]="5" [readonly]="true" class="rating"></ngb-rating>
                    <span class="no-rating"> ({{item.ratingCount || 0}})</span>
                  </div>
              </div>
              </div>
              <div class="col-12">
                <div class="address">
                  {{item.address}}
                </div>
                <div class="description">
                  {{item.businessDescription}}
                </div>
              </div>
              <!-- Icons -->
              <div class="col-12">
                <div class="row">
                  <div class="col-6 mt-3">
                    <a [href]="getWebUrl(item.ctaUrl)" target="_blank" class="btn btn-primary btn-cta"  *ngIf="item.ctaUrl">{{item.ctaText}}</a>
                  </div>
                  <div class="col-6 card-icons text-right pr-2">
                    <a [href]="'//www.google.com/maps/search/?api=1&query='+item.latitude+','+item.longitude" target="_blank" *ngIf="item.latitude && item.longitude">
                      <em class="fa fa-map-marker-alt"></em>
                    </a>
                    <em class="fa fa-map-marker-alt social-no-data" *ngIf="!item.latitude || !item.longitude"></em>
                    <em class="brd-right"></em>

                    <a [href]="'tel:' + item.phoneNo" [ngbTooltip]="item.phoneNo" *ngIf="item.phoneNo">
                      <em class="fa fa-phone-volume"></em>
                    </a>
                    <em class="fa fa-phone-volume social-no-data" *ngIf="!item.phoneNo"></em>
                    <em class="brd-right"></em>

                    <a [href]="'mailto: ' + item.email" target="_blank" [ngbTooltip]="item.email" *ngIf="item.email">
                      <em class="fa fa-envelope"></em>
                    </a>
                    <em class="fa fa-envelope social-no-data" *ngIf="!item.email"></em>
                    <em class="brd-right"></em>

                    <a [href]="getWebUrl(item.website)" target="_blank" [ngbTooltip]="item.website" *ngIf="item.website">
                      <em class="fa fa-globe"></em>
                    </a>
                    <em class="fa fa-globe social-no-data" *ngIf="!item.website"></em>
                    <em class="brd-right"></em>

                    <a [href]="getWebUrl(item.wpUrl)" target="_blank" [ngbTooltip]="item.wpUrl" *ngIf="item.wpUrl">
                      <em class="fab fa-whatsapp"></em>
                    </a>
                    <em class="fab fa-whatsapp social-no-data" *ngIf="!item.wpUrl"></em>
                    <em class="brd-right"></em>

                    <a [href]="getWebUrl(item.fbUrl)" target="_blank" [ngbTooltip]="item.fbUrl" *ngIf="item.fbUrl">
                      <em class="fab fa-facebook-f"></em>
                    </a>
                    <em class="fab fa-facebook-f social-no-data" *ngIf="!item.fbUrl"></em>
                    <em class="brd-right"></em>

                    <a [href]="getWebUrl(item.instaUrl)" target="_blank" [ngbTooltip]="item.instaUrl" *ngIf="item.instaUrl">
                      <img class="fab instagram" src="../../assets/instagram-logo-png-2426.png" alt="insta" />
                    </a>
                    <em class="fab fa-instagram social-no-data" *ngIf="!item.instaUrl"></em>
                    <em class="brd-right"></em>

                    <a [href]="getWebUrl(item.twitterUrl)" target="_blank" [ngbTooltip]="item.twitterUrl" *ngIf="item.twitterUrl">
                      <em class="fab fa-twitter"></em>
                    </a>
                    <em class="fab fa-twitter social-no-data" *ngIf="!item.twitterUrl"></em>
                    <em class="brd-right"></em>

                    <a [href]="getWebUrl(item.linkedinUrl)" target="_blank" *ngIf="item.linkedinUrl">
                      <em class="fab fa-linkedin-in"></em>
                    </a>
                    <em class="fab fa-linkedin-in social-no-data" *ngIf="!item.linkedinUrl"></em>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="card">
          <div class="rating text-right pr-3 my-1">
            <ngb-rating [rate]="3" [max]="5" [readonly]="true" class="rating"></ngb-rating>
            <span class="no-rating"> (2345)</span>
          </div>
          <div class="card-img" [style.background-image]="'url(' + item.img + ')'"></div>
          <div class="card-body">
            <div class="card-title">Ali Cleaning and Laundary Services<span class="ssm"> (123456-S)</span></div>
            <hr class="my-2">
            <div class="category"><span style="color: #818181;">in</span> General Cleaning, Commercial Cleaning</div>
            <p class="address">
              35B, Jalan Nova U5/N, Subang Bestari, 40150 Shah Alam, Selangor
            </p>
            <div class="text-center">
              <a href="#" class="btn btn-primary btn-cta">Inquire Within</a>
            </div>
          </div>

          <hr class="my-0 mx-4">

          <div class="card-icons text-center">
            <em class="fa fa-map-marker-alt"></em>
            <em class="brd-right"></em>
            <em class="fa fa-phone-volume"></em>
            <em class="brd-right"></em>
            <em class="fa fa-envelope"></em>
            <em class="brd-right"></em>
            <em class="fa fa-globe"></em>
            <em class="brd-right"></em>
            <em class="fa fa-info-circle"></em>
          </div>
        </div> -->
      <!-- Business Cards End -->
        </div>

        <!-- Custom Pagination Control -->
        <div class="col-12 text-center mt-4">
          <pagination-controls class="my-pagination" (pageChange)="pageChanged($event)" maxSize="5"></pagination-controls>
        </div>
      </div>
    </div>
    <!-- List View Ends -->
  </div>
  <!-- Listings Section Ends -->
</div>
